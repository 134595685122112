<template>
	<div v-if="authed" class="px-2">
		<v-tabs style="width:100%">
			<v-tab>套餐价格组成</v-tab>
			<v-tab-item class="pt-2">
				<v-form ref="formPrice">
					<v-simple-table style="border:1px solid #cccccc;width:400px;">
						<template v-slot:default>
							<thead style="background-color: #eee">
								<tr>
									<th></th>
									<th v-for="h in headers" :key="h">{{h}}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in items" :key="item.value">
									<td width="25%">{{ item.text }}</td>
									<td v-for="h in headers" :key="h" width="25%">
										<v-text-field v-model.number="pricelist[item.value][h]" :min="0" type="number" :rules="numberRules"
											hide-spin-buttons single-line class="mb-1"reverse/>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-form>
				<v-btn color="primary" @click.stop="savePricelist" :loading="loading" :disabled="loading" width="100" class="mt-3">保存</v-btn>
			</v-tab-item>
			<v-tab>预约时限提示</v-tab>
			<v-tab-item class="pt-2">
				<v-form ref="formApnt">
					<v-simple-table style="border:1px solid #cccccc;width:270px;">
						<template v-slot:default>
							<thead style="background-color: #eee">
								<tr>
									<th></th>
									<th v-for="h in headers1" :key="h">{{h}}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in items1" :key="item.value">
									<td width="35%">{{ item.text }}</td>
									<td v-for="h in headers1" :key="h" width="30%">
										<v-text-field v-model.number="apntdeadline[item.value][h]" :min="0" type="number" :rules="numberRules"
											hide-spin-buttons single-line class="mb-1" placeholder="提示阈值(小时)" reverse/>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-form>
				<v-btn color="primary" @click.stop="saveApntDeadline" :loading="loading" :disabled="loading" width="100" class="mt-3">保存</v-btn>
			</v-tab-item>
			<v-tab>安全IP列表</v-tab>
			<v-tab-item class="pt-2">
				<v-form ref="formIp">
					<v-simple-table style="border:1px solid #cccccc;width:200px;">
						<template v-slot:default>
							<thead style="background-color: #eee">
								<tr>
									<th>IP地址</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(ip, i) in ips" :key="i">
									<td>
										<v-text-field v-model.trim="ips[i]" single-line class="mb-1" placeholder="IP地址" :rules="ipRules"/>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-form>
				<v-btn color="primary" @click.stop="saveIps" :loading="loading" :disabled="loading" width="100" class="mt-3">保存</v-btn>
			</v-tab-item>
		</v-tabs>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
import {colorsptz} from '../workset3'

export default {
	data() {
		return {
			authed: false,
			loading: false,
			numberRules: [v => (/^\d+(\.\d+)?$/g).test(v) || '只允许数字'],
			ipRules: [v => !v || /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/g.test(v) || '请输入有效IP地址'],
			headers: ['胃肠镜','胃镜','肠镜'],
			items: [
				{text:'基础价格',value:'base'},
				{text:'麻醉费',value:'anes'},
				{text:'专家费',value:'pro'}
			],
			pricelist: {
				base: {},
				pro: {},
				anes: {},
			},
			headers1: ['预约','取消预约'],
			items1: colorsptz.map(x => ({text:x.text, value:x.tag})),
			apntdeadline: colorsptz.reduce((a, v) => ({...a, [v.tag]:{}}), {}),
			ips: Array(5).fill(''),		//fill了才能遍历
		}
	},
	mounted() {
		this.authed = this.$hasPrivilege(['业务设置']);
		if (!this.authed) return;
		this.fetchData();
	},
	methods: {
		async fetchData() {
			const db = this.$tcbapp.database();
			const _ = db.command;
			this.loading = true;
			try {
				const res = await db.collection('workset').doc('pricelist').get();
				this.pricelist = res.data?.[0] || this.$options.data().pricelist;
				const res1 = await db.collection('workset').doc('apntdeadline').get();
				this.apntdeadline = res1.data?.[0] || this.$options.data().apntdeadline;
				const res2 = await db.collection('workset').doc('ips').get();
				this.ips.forEach((x,i,a) => a[i] = res2.data?.[0]?.ips[i] || '');
			} catch(err) {
				console.error(err);				
			}
			this.loading = false;
		},
		async savePricelist() {
			if (!this.$refs.formPrice.validate()) return;
			this.loading = true;
			try {
				await this.$callCloudFunc({
					funcname:'savePricelist',
					data: this.pricelist,
				});
				this.$dialog.message.success('保存完成');
			} catch(err) {
				this.$dialog.message.error('保存失败');
				console.error(err);
			}
			this.loading = false;
		},
		async saveApntDeadline() {
			if (!this.$refs.formApnt.validate()) return;
			this.loading = true;
			try {
				await this.$callCloudFunc({
					funcname:'saveApntDeadline',
					data: this.apntdeadline,
				});
				this.$dialog.message.success('保存完成');
			} catch(err) {
				this.$dialog.message.error('保存失败');
				console.error(err);
			}
			this.loading = false;
		},
		async saveIps() {
			if (!this.$refs.formIp.validate()) return;
			this.loading = true;
			try {
				await this.$callCloudFunc({
					funcname:'saveIps',
					data: {ips: this.ips.filter(x => !!x)},
				});
				this.$dialog.message.success('保存完成');
			} catch(err) {
				this.$dialog.message.error('保存失败');
				console.error(err);
			}
			this.loading = false;
		},
	},
}
</script>